import React from "react"
import { useQuery } from "@apollo/react-hooks"
import { GET_AUTH_TOKEN } from "./../gql"
import LayoutContainer from "./../components/layout"
import Throbber from "./../components/throbber"
import { isAuthRoute, isBrowser } from "../utilities/helpers"
import { navigate } from "gatsby"
import { APP_ROUTES } from "../constants"

const AuthGate = ({ location }) => {
  const { loading, error, data } = useQuery(GET_AUTH_TOKEN)
  const { localAuthToken, sessionAuthToken } = data
  const welcomeDismissed = isBrowser()
    ? localStorage.getItem("welcomeDismissed")
    : null

  if (loading) return <Throbber loadingText="Loading." relative />
  if (error)
    return (
      <LayoutContainer path={location.pathname}>
        <div className="center-align">
          <p className="text">{error.message}</p>
        </div>
      </LayoutContainer>
    )

  if (isBrowser()) {
    if (welcomeDismissed === null) {
      navigate(APP_ROUTES.intro)
    } else if (localAuthToken === null && sessionAuthToken === null) {
      if (!isAuthRoute(location.pathname)) {
        navigate(APP_ROUTES.login)
      }
    } else {
      // User is already logged in
      navigate(APP_ROUTES.home)
    }
  }
  return null
}

export default AuthGate
